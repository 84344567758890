




































import axios from "axios";
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import PackageList from "./../components/PackageList.vue";
import ApiKeys from "./../components/ApiKeys.vue";
import { VeinShard } from "./../models";

@Component({
  components: {
    "package-list": PackageList,
    "api-keys": ApiKeys
  }
})
export default class Profile extends Vue {
  metaInfo() {
    return  {
      title: `${this.$store.state.user?.displayName}`
    }
  }

  @Ref("_apiKeys_window_") readonly apiKeysWindow!: ApiKeys
  me2Packages: VeinShard[] = [];
  async created() {
    let result = await this.$axios.$get("/@/me/packages");
    this.me2Packages = result.data;
    console.log(this.me2Packages);
  }

  get packages(): VeinShard[] {
    return this.me2Packages;
  }

  changeAvatar() {
    console.log("trying change avatar...");
    throw new Error();
  }

  openApiKeysWindow() {
    this.apiKeysWindow.toggleApiKeys();
  }
  
}
