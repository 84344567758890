











































import "reflect-metadata";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { VeinShard } from "./../models";
import VerifiedBadge from "./VerifiedBadge.vue";

@Component({
  components: {
    "verified-badge": VerifiedBadge
  },
})
export default class PackageList extends Vue {
  @Prop() packages!: VeinShard[];
  page: number = 1;
  @Prop() readonly pageSize!: Number;
  @Prop() showVersion!: boolean;

  goToPackageView(c: VeinShard) {
    this.$router.push(`/package/${c.name}/${c.version}`);
  }

  get getPageSize(): Number {
    return this.pageSize ?? 4;
  }

  get getPackages(): VeinShard[] {
    return this.packages ?? [];
  }

  getTimeAgo(p: VeinShard) {
    let time = new Date(Date.parse(p.published)); 
    return this.$timeAgo.format(time);
  }
}
