










































































































import "reflect-metadata";
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { VsTable } from "vuesax";
type apiKey = {
  uid: string;
  userOwner: string;
  creationDate: string;
  eol: string;
  expiresDate: string;
  name: string;
  destroyed: boolean;
};

@Component({
  components: {},
})
export default class ApiKeys extends Vue {
  active: boolean = false;
  apiKeyName: string = "";
  creationActive: boolean = false;
  creationLoading: boolean = false;
  isLoading: boolean = true;
  the60Days: number = 1;
  apiKeys: apiKey[] = [];
  showApiKey: boolean = false;
  lastApiKey: string = "";
  @Ref("_table_apikeys") tableOfApiKeys: VsTable;

  constructor() {
    super();
  }

  toggleApiKeys() {
    this.isLoading = true;
    this.apiKeys = [];
    this.active = !this.active;
    this.load();
  }

  get inputApiKeyName() {
    return this.apiKeyName;
  }
  set inputApiKeyName(val: string) {
    this.apiKeyName = val;
  }

  async load() {
    let result = await this.$axios.$get("/@/me/token");
    this.apiKeys = result.data;
    this.isLoading = false;
    console.log(this.tableOfApiKeys);
  }

  copyApiKey() {
    console.log("Copied!");
    this.$vs.notification({
      icon: `<i class='bx bx-copy' style='font-size: 2.2rem;'></i>`,
      color: `info`,
      position: `top-left`,
      title: 'Copied!',
      text: `Api key copied into clipboard buffer!`,
      duration: 3000
    });
    navigator.clipboard.writeText(this.lastApiKey);
    this.showApiKey = false;
    this.creationActive = false;
  }

  async createApiKey() {
    this.creationLoading = true;
    console.log(this.apiKeyName);
    try {
      let result = await this.$axios.$post(
        `@/me/token/new?name=${encodeURIComponent(this.apiKeyName)}`,
        {}
      );
      let apiKey = result.data as apiKey;
      this.showApiKey = true;
      this.lastApiKey = apiKey.uid;
      this.apiKeys.push(apiKey);
      this.creationActive = false;
    } catch (e) {
      this.creationActive = true;
    }
  }

  async removeApiKey(uid: string) {
    this.isLoading = true;
    let key = this.apiKeys.find((x: apiKey) => x.uid == uid);

    if (!key) return;

    console.log(`Removing '${key.name}' api key...`);
    try {
      let result = await this.$axios.$delete(
        `@/me/token/${key.uid}`
      );
      console.log(result);
      if (result.data == true) {
        this.isLoading = false;
        // the shit, rly
        this.$refs[`fucking_expand_${uid}`]![0]!.$el.click();
        this.apiKeys = this.apiKeys.filter((x: apiKey) => x.uid != uid);
      }
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  getExpTime(date: Date) {
    if (date instanceof Date)
      return this.$timeAgo.format(date.getTime(), { future: true });
    return this.$timeAgo.format(Date.parse(date), { future: true });
  }
}
